import React, { ReactElement, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  createStyles,
  Grid,
  Paper,
  StyleRules,
  SwipeableDrawer,
  TextField,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
// import PersonAddIcon from '@material-ui/icons/PersonAdd';
// import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Cascader } from 'antd';
import { Modal } from 'antd';
import { navigate } from 'gatsby';

import { getAllProjectRequest } from '../../actions/projectRequestAction';
import { CancelCompanyPublishProjectDialog } from '../../components/Dialog';
import { AppState } from '../../reducers';
import { companyMenuAction } from '../../reducers/companyMenuReducer';
import { FProjectRequestStatusEnum } from '../../reducers/projectRequestReducer';
import CITY from '../../utils/city';
import cityChild from '../../utils/cityChild';
import COUNTRY from '../../utils/country';
// import dataIndustry from '../../utils/industry';
import PROVINCE from '../../utils/province';
// import specialData from '../../utils/special';
import BidScreening from '../consultant/bidScreening';
import PublishProjectView from './PublishProjectView';

const styles = (): StyleRules =>
  createStyles({
    DataGridMargin: {
      minHeight: '80vh',
    },
    contentPadding: {
      padding: '10px 0',
    },
    childrenItem: {
      width: '100%',
      borderRadius: '3px',
      // minHeight: '80vh',
      paddingBottom: '48px',
    },
    buttonMargin: {
      margin: '0 0.25rem',
    },
    DialogWidth: {
      minWidth: '850px',
      border: '20px solid red',
    },
    searchInput: {
      margin: '20px',
    },
    backgroundColor: {
      backgroundColor: '#ffffff',
    },
    textTitle: {
      textAlign: 'center',
    },
    multiple: {
      margin: '1rem',
    },
    statusError: {
      color: '#fff',
      display: 'line-block',
      borderRadius: '2px',
      padding: '0.3rem',
      backgroundColor: 'rgb(154, 0, 54)',
    },
    statusFinished: {
      color: '#fff',
      display: 'line-block',
      borderRadius: '2px',
      padding: '0.3rem',
      backgroundColor: '#0069d9',
    },

    statusPublished: {
      color: '#fff',
      display: 'line-block',
      borderRadius: '2px',
      padding: '0.3rem',
      backgroundColor: '#388e3c',
    },

    statusSubmitted: {
      color: '#fff',
      display: 'line-block',
      borderRadius: '2px',
      padding: '0.3rem',
      backgroundColor: '#d5d5d5',
    },
    textMargin: {
      margin: '0 0.5rem',
    },
  });

const projectListView = ({ classes }: Props): ReactElement => {
  FProjectRequestStatusEnum;
  const dispatch = useDispatch();
  const projectListReducer = useSelector((appState: AppState) => appState.projectListReducer);
  const [editOpen, setEditOpen] = React.useState(false);
  const [isCancelDialog, setIsCancelDialog] = useState(false);
  const [projectRowData, setProjectRowData] = useState([]);

  const [bid, setBid] = useState();

  const handleEditClose = () => {
    setEditOpen(false);
  };

  const columns: GridColDef[] = [
    {
      field: 'projectName',
      headerName: '项目名称',
      // editable: true,
      flex: 1,
    },
    {
      field: 'workScenario',
      headerName: '工作方式',
      width: 150,
      renderCell: params => {
        const workScenario = JSON.parse(params.row.workScenario);
        return (
          <>
            {workScenario.onsite && <span className={classes.textMargin}>线下</span>}
            {workScenario.remote && <span className={classes.textMargin}>远程</span>}
          </>
        );
      },
    },
    {
      field: 'workLocationCity',
      headerName: '工作地点',
      type: 'number',
      width: 200,
      renderCell: params => {
        return `${CITY.find(city => city.code === params.row.workLocationCity)?.name}-${
          params.row.workLocationDistrict
            ? cityChild.find(cityChild => cityChild.code === params.row.workLocationDistrict)?.name
            : ''
        }`;
      },
    },
    // {
    //   field: 'status',
    //   headerName: '状态',
    //   align: 'center',
    //   width: 130,
    //   renderCell: params => {
    //     return (
    //       <div>
    //         {params.row.status === 'SUBMITTED' ||
    //         params.row.status === 'EDITING' ||
    //         params.row.status === 'NONE' ? (
    //           <span className={classes.statusSubmitted}>待审核</span>
    //         ) : (
    //           ''
    //         )}
    //         {params.row.status === 'PUBLISHED' ? (
    //           <span className={classes.statusPublished}>发布中</span>
    //         ) : (
    //           ''
    //         )}
    //         {params.row.status === 'FINISHED' ? (
    //           <span className={classes.statusFinished}>已完成</span>
    //         ) : (
    //           ''
    //         )}
    //         {params.row.status === 'DENIED' ? (
    //           <span className={classes.statusError}>审核失败</span>
    //         ) : (
    //           ''
    //         )}
    //       </div>
    //     );
    //   },
    // },
    {
      field: 'actions',
      headerName: '操作',
      type: 'number',
      width: 380,
      sortable: false,
      renderCell: params => {
        return (
          <>
            <Button
              type="button"
              variant="contained"
              color="primary"
              size="small"
              className={classes.buttonMargin}
              onClick={() => {
                navigate('/projectRequest/projectPreview/' + '#' + params.row.id, {
                  state:
                    params.row.status === 'SUBMITTED' || params.row.status === 'EDITING'
                      ? { row: params.row.id }
                      : '',
                });
              }}
            >
              <VisibilityIcon fontSize="small" /> 查看
            </Button>
            <Button
              type="button"
              variant="contained"
              color="primary"
              size="small"
              className={classes.buttonMargin}
              onClick={() => {
                setEditOpen(true);
                setBid(params.row.id);
              }}
            >
              <EditIcon fontSize="small" /> 编辑项目
            </Button>
            <Button
              type="button"
              variant="contained"
              color="primary"
              size="small"
              className={classes.buttonMargin}
            >
              <EditIcon fontSize="small" /> 下线
            </Button>
          </>
        );
      },
    },
  ];
  const [DrawerOpen, setDrawerOpen] = useState(false);
  const [industryArr, setIndustryArr] = useState([]);
  const [addressState, setAddressState] = useState([]);
  const [specialArr, setSpecialArr] = useState([]);
  industryArr;
  addressState;
  specialArr;
  const toggleDrawer = (open: boolean) => () => {
    setDrawerOpen(open);
  };

  const { SHOW_CHILD } = Cascader;
  SHOW_CHILD;
  const AreaOptions = COUNTRY.map(Item => {
    if (Item.name == '中国') {
      const provinceList = PROVINCE.map(item => {
        const cdata = CITY.map(citem => {
          if (item.code == citem.provinceCode) {
            return { label: citem.name, value: citem.code };
          }
        });
        const r = cdata.filter(s => {
          return s != undefined;
        });
        return { label: item.name, value: item.code, children: [...r] };
      });
      return { label: Item.name, value: Item.value, children: [...provinceList] };
    }
    return { label: Item.name, value: Item.value, children: [] };
  });
  AreaOptions;
  const areaOnChange = (value: []): void => {
    if (value.length > 3) {
      setAddressState(value.slice(0, 3));
    } else {
      setAddressState([...value]);
    }
  };
  areaOnChange;
  const industryOnChange = (value: []) => {
    if (value.length > 3) {
      setIndustryArr(value.slice(0, 3));
    } else {
      setIndustryArr([...value]);
    }
  };
  industryOnChange;
  const specialOnChange = (value: []) => {
    if (value.length > 3) {
      setSpecialArr(value.slice(0, 3));
    } else {
      setSpecialArr([...value]);
    }
  };
  specialOnChange;
  const [filters, setFilters] = useState<FilterDateType>();

  const handleFilterChange = (newFilters: FilterDateType) => {
    setFilters(newFilters);
  };
  filters;
  const [searchValue, setSearchValue] = useState('');
  const findItems = () => {
    if (searchValue) {
      const filteredData = projectRowData.filter((item: ProjectRequestInfo) => {
        if (item.projectName) {
          return item.projectName.includes(searchValue);
        } else {
          return false;
        }
      });
      setProjectRowData([...filteredData] as []);
    }
  };

  const projectOnChange = (Event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(Event.target.value);
    if (!Event.target.value) {
      if (projectListReducer && projectListReducer.projectList?.length > 0) {
        setProjectRowData([...projectListReducer.projectList] as []);
      }
    }
  };

  const fetchData = async () => {
    await dispatch(getAllProjectRequest());
  };

  useEffect(() => {
    fetchData();
    dispatch(
      companyMenuAction({
        menuStatus: {
          home: false,
          publishProject: false,
          projectList: true,
          biddingList: false,
          pendingProject: false,
        },
      })
    );
  }, []);

  useEffect(() => {
    if (projectListReducer && projectListReducer.projectList?.length > 0) {
      setProjectRowData([...projectListReducer.projectList] as []);
    }
  }, [projectListReducer]);

  return (
    <div className={classes.contentPadding}>
      <Helmet title="企业项目管理"></Helmet>

      <Grid container xs={12}>
        <Grid item xs={6} container>
          <TextField
            className={`${classes.searchInput} ${classes.backgroundColor}`}
            id="outlined-search"
            label="查找项目"
            fullWidth={true}
            type="search"
            value={searchValue}
            onChange={projectOnChange}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={3} container>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            className={classes.searchInput}
            onClick={findItems}
          >
            搜索
          </Button>
        </Grid>
      </Grid>
      <Paper square className={classes.searchInput}>
        <DataGrid
          rows={projectRowData}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          className={classes.DataGridMargin}
        />
      </Paper>

      {/* <Dialog onClose={handleEditClose} open={editOpen} maxWidth="lg">
        <DialogTitle id="customized-dialog-title" onClose={handleEditClose}>
          编辑项目
        </DialogTitle>
        <DialogContent dividers>
          <PublishProjectView editProject={true} isPublish={true} bid={bid}></PublishProjectView>
        </DialogContent>
      </Dialog> */}

      <Modal
        open={editOpen}
        title="编辑项目"
        footer={null}
        onCancel={handleEditClose}
        width="70rem"
        // style={{ height: '80%', overflowY: 'auto' }}
      >
        <PublishProjectView
          editProject={true}
          isPublish={true}
          bid={bid}
          EditClose={handleEditClose}
          projectListData={fetchData}
        ></PublishProjectView>
      </Modal>

      <CancelCompanyPublishProjectDialog
        isOpen={isCancelDialog}
        handleEdit={() => {
          setIsCancelDialog(false);
        }}
        handleDialogClose={() => {
          setIsCancelDialog(false);
        }}
      ></CancelCompanyPublishProjectDialog>
      <SwipeableDrawer
        anchor="right"
        open={DrawerOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <BidScreening
          onChangefilter={handleFilterChange}
          className={classes.searchInput}
        ></BidScreening>
      </SwipeableDrawer>
    </div>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
}

export default withStyles(styles)(projectListView);
